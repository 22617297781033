<template lang="pug">
  .tab-main-container
    .headline 記録詳細
    .text-center
      b-spinner.mt-5.mb-5(variant="primary" label="Loading..." v-if="isLoading")
    #search-results(v-if="this.rockyList.length == 0 && !isLoading") {{ this.message }}
    .d-flex.flex-wrap
      a.d-flex.align-items-end.col-6.col-md-3.p-2(href="#" v-for="rocky in this.rockyList" @click.prevent="callRockList(allRocky[rocky].rockyId)")
        .position-relative
          img.w-100(:src="makeImgPath(allRocky[rocky].imgPath)" :alt="allRocky[rocky].name")
          .rocky-name.position-absolute.w-100.p-1.text-white {{ allRocky[rocky].name }}
</template>

<script>
import axios from 'axios'

export default {
  // データオブジェクト
  data() {
    return {
      message: '',
      rockyimgPath: '',
      allRocky: {},
      // ログイン済みかどうか
      showRockyList: false,
      isLogin: this.$user.isLogin,
      rockyList: [],
      isLoading:true,
      //cssの制御に使用
      isNotIE:true,
    }
  },
  created() {
    this.createdProcess()
    //cssの制御の為にブラウザ判定を行う
    const ua = navigator.userAgent;
    if(ua.indexOf('Trident') !== -1) {
      //IE 11の場合
      this.isNotIE = false
    }
  },
  // メソッド
  methods: {
    createdProcess(){
      //岩場情報Listを取得
      this.getRockyRecordList()
    },
    callRockList(rockyId){
      this.$router.push({ name: 'myPage_:userId_rockList', params: { userId: this.$route.params.userId }, query: { rockyId: rockyId} })
    },
    makeImgPath(imgPath){
      return this.getRockyThumbPath(imgPath,'360')
    },
    getRockyRecordList() {
      //岩場画像パス取得
      let imgJsonUrl = process.env.VUE_APP_MATERIAL_URL + '/allRockyList.json'
      axios
        .get(imgJsonUrl)
        .then(response => {
          for(let i=0; i<response.data.length;i++) {
            let item = response.data[i]
            this.allRocky[item.rockyId] = item
          }
          
          //岩場リスト取得
          const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-record'
          axios.get(jsonUrl,{
            params: {
              'targetUserId': this.$route.params.userId,
              'type': 'rockyList',
            }, 
            headers: {
              'Authorization': this.$user.isLogin ? this.$user.idToken : 'no-login'
            }
          })
            .then(response => {
              if(response.data == null) {
                //チェックイン履歴とれなかった場合
                this.message = '非公開'
              }else if(response.data.length == 0) {
                //チェックイン履歴0件の場合
                this.message = '記録がありません'
              }else if(response.data.length > 0) {
                //チェックイン履歴ある場合
                this.rockyList = response.data
              }
              this.isLoading = false
            })
            .catch(err => {
              this.isLoading = false
              this.message = '情報の取得に失敗しました'
            })
        })
        .catch(err => {
          this.isLoading = false
          this.message = '情報の取得に失敗しました'
        })
    },
  },
  // 算出プロパティ
  computed: {
  },
  // コンポーネント
  components: {
  }
}
</script>

<style lang="stylus" scoped>
#search-results
  margin 1rem
.rocky-name
  bottom 0
  background-color rgba(0, 0, 0, 0.5)
//IE11以外のブラウザの場合
.isNotIEJustifyContentCenter
  justify-content center //IE11の場合これが効かない対策
</style>
